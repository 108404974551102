import React, { useRef } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../../util/reactIntl';
import { CustomSearchForm, ImageSlider, NamedLink } from '../../../components';
import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration from '../../../routing/routeConfiguration';
import IconArrowRight from '../../../components/IconArrowRight/IconArrowRight';
import '../../ProfilePage/QuickFilters/Carousel/React-Multi-Carousel.css';

import css from './SectionHero.module.css';
import config from '../../../config';

const SectionHero = props => {
  const { rootClassName, className, intl, history, isAuthenticated } = props;

  const classes = classNames(rootClassName || css.root, className);
  const keywordRef = useRef('');

  const handleSubmit = values => {
    const { keywords = '' } = values;
    const query = keywords && keywords.length ? { keywords } : {};
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, query));
  };

  const images = [
    `${config.canonicalRootURL}/static/images/SectionHero3.jpg`,
    `${config.canonicalRootURL}/static/images/banner1.webp`,
    `${config.canonicalRootURL}/static/images/banner2.webp`,
    `${config.canonicalRootURL}/static/images/SectionHero1.jpg`,
    `${config.canonicalRootURL}/static/images/Tutulistpart3.webp`,
    `${config.canonicalRootURL}/static/images/SectionHero2.jpg`,
  ];

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <div className={css.heroLeftContent}>
          <div className={css.heroInnerContent}>
            <div className={css.joinUsbox}>
              {isAuthenticated ? (
                <>
                  <span> Become a seller!</span>
                  <NamedLink name="NewListingPage">
                    Create Listing <IconArrowRight />
                  </NamedLink>
                </>
              ) : (
                <>
                  <span>You’ll find it here!</span>
                  <NamedLink name="SignupPage">
                    Join our Community <IconArrowRight />
                  </NamedLink>
                </>
              )}
            </div>
            <h1 className={css.heroMainTitle}>
              <FormattedMessage id="SectionHero.title" />
            </h1>
            <h2 className={css.heroSubTitle}>
              <FormattedMessage id="SectionHero.subTitle" />
            </h2>
            <CustomSearchForm
              className={css.filterSearchBox}
              onSubmit={handleSubmit}
              intl={intl}
              ref={keywordRef}
              isLandingPage={true}
              placeholder="Leotards, Warmups, Shoes..."
            />
          </div>
        </div>
        <div className={css.heroRightContent}>
          <ImageSlider
            images={images}
            isMobile={true}
            showDots={false}
            animationDuration={2000}
            isSectionHero={true}
            mouseTracking={false}
            disableDotsControls={true}
            pushToListingPage={() => {}}
          />
        </div>
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
