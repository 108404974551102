import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  ListSlider,
  CategorySlider,
  CardSpinner,
  ContentSlider,
  Icons,
  IconSpinner,
  Button,
} from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import facebookImage from '../../assets/sneakertimeFacebook-1200x630.png';
import twitterImage from '../../assets/sneakertimeTwitter-600x314.png';
import SectionHero from './SectionHero/SectionHero';
import { withViewport } from '../../util/contextHelpers';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import classNames from 'classnames';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import {
  combineAndSortListings,
  getBookmarks,
  getRecentlyViewed,
  getWishlist,
  hideEmailVerificationBanner,
  isArrayLength,
} from '../../util/dataExtractors';
import { FormattedMessage } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import SellerShopCard from '../../components/SellerShopCard/SellerShopCard';
import shopSmallImg from '../../assets/shop_small_logo.png';
import css from './LandingPage.module.css';

import { COSTUMES, TUTULIST } from '../../util/enums';
import { addProductToGMC } from './LandingPage.duck';
import RenderEmailVerification from './RenderEmailVerification';
// import { decryptString } from '../../util/securityHelpers';

const { UUID } = sdkTypes;

export const LandingPageComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    viewport,
    listings,
    isAuthenticated,
    currentUser,
    onUpdateProfile,
    queryInProgress,
    sellersData,
    recentlyViewed,
    recentlyAddedListings,
    updateInProgress,
    onAddProductToGMC,
  } = props;

  const [customLoader, setCustomLoader] = useState(true);

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format

  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  const bookmarks = getBookmarks(currentUser);
  const wishlist = getWishlist(currentUser);

  const isSmallListings = isArrayLength(sellersData)
    ? sellersData.filter(item => item?.isSmall)
    : [];

  const featuredListings = isArrayLength(sellersData)
    ? sellersData.filter(item => !item?.isSmall)
    : [];

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer listings={listings} />
          <RenderEmailVerification
            intl={intl}
            customLoader={customLoader}
            setCustomLoader={setCustomLoader}
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain listings={listings}>
          <div className={css.heroContainer}>
            {/* <Button onClick={() => onAddProductToGMC()}>Click</Button> */}
            <SectionHero
              rootClassName={css.heroRoot}
              className={css.hero}
              history={history}
              location={location}
              intl={intl}
              isAuthenticated={isAuthenticated}
            />
          </div>
          <div className={classNames(css.commonSection, css.topCategorySection, css.greyBg)}>
            <div className={css.fixWidthContainer}>
              <CategorySlider
                title="CategorySlider.title"
                name="Category"
                viewport={viewport}
                intl={intl}
                sliderHeading={css.sliderHeading}
                homeMobileSlider={css.homeMobileSlider}
                headingClass={css.secHeader}
                categories={config.custom.shopByCategory}
                numberOfCards={4}
                isTopCategorySection={true}
              />
            </div>
          </div>
          <div className={classNames(css.buySellSection)}>
            <div className={css.fixWidthContainer}>
              <h2>
                <FormattedMessage id="LandingPage.buySell" />
              </h2>
              <p>
                <FormattedMessage id="LandingPage.buySellDescription" />
              </p>
            </div>
          </div>
          <div
            id="added"
            className={classNames(css.commonSection, css.recentlyAddedSection, css.greyBg)}
          >
            <div className={css.fixWidthContainer}>
              {customLoader ? (
                <>
                  {' '}
                  <div className={css.secHeader}>
                    <h2 className={css.sectionTitle}>
                      <FormattedMessage id="LandingPage.recentlyAdded" />
                    </h2>
                  </div>
                  <div className={css.spinner}>
                    <CardSpinner />
                  </div>
                </>
              ) : (
                <ListSlider
                  title="LandingPage.recentlyAdded"
                  viewport={viewport}
                  listings={
                    isArrayLength(recentlyAddedListings) && recentlyAddedListings?.slice(0, 12)
                  }
                  intl={intl}
                  name="Added"
                  sliderHeading={css.sliderHeading}
                  homeMobileSlider={css.homeMobileSlider}
                  bookmarks={bookmarks}
                  isAuthenticated={isAuthenticated}
                  currentUser={currentUser}
                  wishlist={wishlist}
                  queryInProgress={queryInProgress}
                  onUpdateProfile={onUpdateProfile}
                />
              )}
            </div>
          </div>
          <div className={classNames(css.commonSection, css.topCategorySection)}>
            <div className={classNames(css.fixWidthContainer, css.exploreSellerSec)}>
              <div className={css.secHeader}>
                <h2>
                  <FormattedMessage id="LandingPage.shopSmall" />
                  <span className={css.titleHighlight}>
                    {' '}
                    <FormattedMessage id="LandingPage.small" />
                  </span>
                  <img src={shopSmallImg} alt="shop small" />
                </h2>
              </div>
              {isArrayLength(sellersData) ? (
                <ContentSlider showExploreNow data={isSmallListings} />
              ) : (
                <div className={css.spinner}>
                  <CardSpinner />
                </div>
              )}
            </div>
          </div>
          <div className={classNames(css.commonSection, css.topCategorySection)}>
            <div className={classNames(css.fixWidthContainer, css.exploreSellerSec)}>
              <div className={css.secHeader}>
                <h2>
                  {' '}
                  <FormattedMessage id="LandingPage.topSellers" />{' '}
                  <span className={css.titleHighlight}>
                    {' '}
                    <FormattedMessage id="LandingPage.featuredSellers" />
                  </span>
                </h2>
              </div>
              {isArrayLength(sellersData) ? (
                <ContentSlider data={featuredListings} />
              ) : (
                <div className={css.spinner}>
                  <CardSpinner />
                </div>
              )}
            </div>
          </div>
          <div id="collection" className={classNames(css.commonSection, css.brandSec, css.greyBg)}>
            <div className={css.fixWidthContainer}>
              <div className={css.brandingCardWrapper}>
                <CategorySlider
                  title="CategorySlider.brand"
                  viewport={viewport}
                  intl={intl}
                  name="Brands"
                  sliderHeading={css.sliderHeading}
                  homeMobileSlider={css.homeMobileSlider}
                  headingClass={css.secHeader}
                  brands={config.custom.shopByBrands}
                  hideTitle={true}
                  numberOfCards={5}
                  isBrandingSection={true}
                />
              </div>
            </div>
          </div>
          {/* <div id="added" className={classNames(css.commonSection, css.recentlyAddedSection)}>
            <div className={css.fixWidthContainer}>
              {customLoader ? (
                <>
                  {' '}
                  <div className={css.secHeader}>
                    <h2 className={css.sectionTitle}>
                      <FormattedMessage id="LandingPage.recentlyAddedd" />{' '}
                      <span className={css.titleHighlight}>
                        <FormattedMessage id="LandingPage.added" />
                      </span>
                    </h2>
                  </div>
                  <div className={css.spinner}>
                    <CardSpinner />
                  </div>
                </>
              ) : (
                <ListSlider
                  title="LandingPage.recentlyAddedd"
                  viewport={viewport}
                  listings={
                    isArrayLength(recentlyAddedListings) && recentlyAddedListings?.slice(0, 12)
                  }
                  intl={intl}
                  name="Added"
                  sliderHeading={css.sliderHeading}
                  homeMobileSlider={css.homeMobileSlider}
                  bookmarks={bookmarks}
                  isAuthenticated={isAuthenticated}
                  currentUser={currentUser}
                  wishlist={wishlist}
                  queryInProgress={queryInProgress}
                  onUpdateProfile={onUpdateProfile}
                />
              )}
            </div>
          </div> */}
          {isArrayLength(recentlyViewed) ? (
            <div className={classNames(css.commonSection, css.recentlyAddedSection, css.greyBg)}>
              <div className={css.fixWidthContainer}>
                {customLoader ? (
                  <>
                    {' '}
                    <div className={css.secHeader}>
                      <h2 className={css.sectionTitle}>
                        <FormattedMessage id="LandingPage.recentlyViewed" />
                      </h2>
                    </div>
                    <div className={css.spinner}>
                      <CardSpinner />
                    </div>
                  </>
                ) : (
                  <ListSlider
                    title="LandingPage.recentlyViewed"
                    viewport={viewport}
                    listings={isArrayLength(recentlyViewed) && recentlyViewed}
                    intl={intl}
                    name="Viewed"
                    sliderHeading={css.sliderHeading}
                    homeMobileSlider={css.homeMobileSlider}
                    bookmarks={bookmarks}
                    isAuthenticated={isAuthenticated}
                    wishlist={wishlist}
                    queryInProgress={queryInProgress}
                    onUpdateProfile={onUpdateProfile}
                    currentUser={currentUser}
                  />
                )}
              </div>
            </div>
          ) : null}
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    searchInProgress,
    featuredSellers,
    popularPageResultIds,
  } = state.LandingPage;
  const { currentUser, currentUserHasListings } = state.user;
  const { isAuthenticated } = state.Auth;
  const { updateInProgress } = state.ProfileSettingsPage;
  const listings =
    currentPageResultIds &&
    currentPageResultIds.length &&
    getListingsById(state, currentPageResultIds);

  const popularListings =
    popularPageResultIds &&
    popularPageResultIds.length &&
    getListingsById(state, popularPageResultIds);

  const tutulistSeller =
    isArrayLength(featuredSellers) && featuredSellers.filter(item => item?.username === TUTULIST);

  const othersSellers =
    isArrayLength(featuredSellers) &&
    featuredSellers?.slice(0, 11)?.filter(item => item?.username !== TUTULIST);

  const userRecentlyViewed = getRecentlyViewed(currentUser);

  const recentlyViewed =
    isArrayLength(userRecentlyViewed) && userRecentlyViewed.map(item => new UUID(item));

  const recentlyViewedListings =
    recentlyViewed && recentlyViewed.length && getListingsById(state, recentlyViewed);

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    isAuthenticated,
    listings: (isArrayLength(listings) && listings) || [],
    recentlyAddedListings: combineAndSortListings(listings),
    popularListings: popularListings?.length ? popularListings : [],
    currentUserHasListings,
    queryInProgress: searchInProgress,
    sellersData:
      Array.isArray(tutulistSeller) &&
      Array.isArray(othersSellers) &&
      tutulistSeller.concat(othersSellers),
    recentlyViewed: (isAuthenticated && recentlyViewedListings) || [],
    updateInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateProfile: data => dispatch(updateProfile(data)),
  onAddProductToGMC: data => dispatch(addProductToGMC(data)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withViewport
)(LandingPageComponent);

export default LandingPage;
