import React, { useEffect } from 'react';
import { hideEmailVerificationBanner, isUserEmailVerified } from '../../util/dataExtractors';
import { selectCurrentUser } from '../../ducks/user.duck';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsAuthenticated } from '../../ducks/Auth.duck';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {
  selectProfileUpdateInProgress,
  updateProfile,
} from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import { IconSpinner, Icons } from '../../components';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routing/routeConfiguration';

import css from './LandingPage.module.css';

function RenderEmailVerification(props) {
  const { intl, customLoader, setCustomLoader } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const banner1Message = intl.formatMessage({ id: 'ModalMissingInformation.verifyEmailText1' });
  const banner2Message = intl.formatMessage({ id: 'ModalMissingInformation.verifyEmailText2' });
  const banner3Message = intl.formatMessage({ id: 'ModalMissingInformation.verifyEmailText3' });

  const currentUser = useSelector(selectCurrentUser);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const updateInProgress = useSelector(selectProfileUpdateInProgress);

  const hideBanner = hideEmailVerificationBanner(currentUser);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCustomLoader(false);
    }, 800);
    return () => clearTimeout(timer);
  }, []);

  if (customLoader || isUserEmailVerified(currentUser) || !isAuthenticated || hideBanner) {
    return <></>;
  }

  const onRedirectToAccountSettings = () => {
    history.push(createResourceLocatorString('ContactDetailsPage', routeConfiguration(), {}, {}));
  };

  return (
    <div className={css.importantNote}>
      {banner1Message}{' '}
      <span role="button" className={css.verifyEmail} onClick={onRedirectToAccountSettings}>
        {banner2Message}
      </span>{' '}
      {banner3Message}
      <span
        className={css.closeBanner}
        role="button"
        onClick={() => {
          dispatch(updateProfile({ protectedData: { hideEmailVerificationBanner: true } }));
        }}
      >
        {updateInProgress ? (
          <span className={css.updateInProgress}>
            <IconSpinner />
          </span>
        ) : (
          <Icons name="close" />
        )}
      </span>
    </div>
  );
}

export default RenderEmailVerification;
